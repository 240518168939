import { Auth } from 'aws-amplify'

export const cognitoForgotPasswordSubmit = async (username, code, password) => {
  let result = {
    isError: false,
  }

  try {
    result.data = await Auth.forgotPasswordSubmit(username, code, password)
  } catch (error) {
    result.isError = true
    result.data = error
  }

  return result
}
