/* eslint-disable prettier/prettier */
export default {
  // 教室のトラスト
  // サイト名
  'おみせのトラスト': 'T*Plats',
  // ヘッダー
  'おみせのトラストは、お店のトラスト情報（衛生対策）を見える化する実証実験です。': 'T*Platsは、教室のトラスト情報(衛生対策)を見える化します。',
  // フッター
  '感染症対策を実施してる店舗を掲載しています。対策状況を確認することで、利用者が店舗を安心して利用できることを実証します。': '教室の感染症対策状況を掲載しています。対策状況を施設関係者および保護者の方に共有することで、教室に対する安心を提供します。',
  // トップ
  '施設の環境をみつめよう。': '施設の環境を<br class="h-spBreak">みつめよう。',
  '現代のニューノーマルに対応した衛生的で安心な施設をご利用いただくため施設の信頼性（トラスト）情報をリアルタイムに見える化します。': '現代のニューノーマルに対応した衛生的で安心な教室をご利用いただくため<br>教室の信頼性（トラスト）情報をリアルタイムに見える化します。',
  '店舗名 / 施設名で探す': '教室名で探す',
  'おみせのトラスト指数': 'T*Plats指数',
  'お店と施設それぞれの衛生対策状況をパーセンテージで示す、おみせのトラスト独自の信頼性指数です。トラスト指数が高いほど、衛生対策の信頼性が高いお店となります。': '教室と教室それぞれの衛生対策状況をパーセンテージで示す、T*Plats独自の信頼性指数です。\nT*Plats指数が高いほど、衛生対策の信頼性が高い空間となります。',
  '{badge}は、IoTセンサーによるリアルタイム状況を表示している店舗です。': '{badge}は、IoTセンサーによるリアルタイム状況を表示している教室です。',
  '{badge}は、第三者によるチェックを行っている店舗です。': '{badge}は、第三者によるチェックを行っている教室です。',
  'IoTセンサー導入店舗': 'IoTセンサー導入教室',
  '第三者によるチェックを行っている店舗': '第三者によるチェックを行っている教室',
  '該当するお店が見つかりませんでした。検索条件を変更して再度お試しください。': '該当する教室が見つかりませんでした。\n検索条件を変更して再度お試しください。',
  // 詳細画面
  'おみせのサイトへ': '教室のサイトへ',
  '対策状況のうち、店舗の対策情報は{shopName}、施設の対策情報は{facilityName}が登録しています。': '対策状況のうち、教室の対策情報は{shopName}、施設の対策情報は{facilityName}が登録しています。',
  '店舗の対策': '教室の対策',
  '施設の対策': '施設の対策',
  'おみせの取り組み': '教室の取り組み',
  // 対策観点
  '店舗消毒': '室内消毒',
  'ドアノブ、券売機、セルフドリンクコーナーなどのお客様フロアにある設備の消毒': 'ドアノブ、机、水道の蛇口などの、生徒、学生などが使用する設備の消毒',
  'お客様の退席後、テーブル上の消毒もしくはテーブルクロスの交換を実施': '生徒、学生などが退出後、使用した設備の消毒',
  'お客様の安全': '生徒、学生などの安全',
  '入口における消毒液を設置、入店時お客様にて消毒実施': '入口における消毒液を設置、入退出時生徒、学生などにて消毒実施',
  '入店時お客様に検温を実施': '生徒、学生などに検温を実施',
  'テーブル間距離': '机間距離',
  'グループ同士で１ｍの間隔が空けられているもしくはパーティションで区切られている': '隣同士で１ｍの間隔が空けられているもしくはパーティションで区切られている',
  '従業員の安全衛生管理': '教職員などの安全衛生管理',
  '従業員の検温、体調管理を実施': '教職員などの検温、体調管理を実施',
  '従業員のマスクまたはフェイスガードの着用': '教職員などのマスクまたはフェイスガードの着用',
  // トラスト指数の内訳
  'お店の衛生対策状況': '生徒教職員の衛生対策状況',
  '換気/店舗消毒/お客様の安全/テーブル間距離/従業員の安全衛生管理/トイレ消毒の6カテゴリのチェック項目': '換気/室内消毒/生徒、学生などの安全/机間距離/教職員などの安全衛生管理/トイレ消毒の6カテゴリのチェック項目、及び、写真掲載有無',
  '施設の衛生対策状況': '施設の衛生対策状況',
  'お店にセンサーを設置し、数値を開示しているか': '教室にセンサーを設置し、数値を開示しているか、及び、測定数値',
}
