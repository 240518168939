import Vue from 'vue'
import Toasted from 'vue-toasted'
import router from '@/router'

Vue.use(Toasted, {
  router,
  position: 'bottom-center',
  duration: 2000,
  keepOnHover: true,
  singleton: true,
})
