/* eslint-disable prettier/prettier */
export default {
  // 共通
  // ヘッダー
  'ログイン': 'Login',
  'ログアウト': 'Logout',
  '表示言語': 'Display Language',
  '表示モード': 'Display Mode',
  '標準': 'Standard',
  'サイネージモード.サイネージモード(タブレット)': 'Signage mode',
  '(タブレット).サイネージモード(タブレット)': ' for the Tablet',
  'サイネージモード.サイネージモード(スマートフォン)': 'Signage mode',
  '(スマートフォン).サイネージモード(スマートフォン)': ' for the Smartphone',
  // フッター
  '{siteName}について': 'About {siteName}',
  '本実証実験は、内閣府が進める戦略的イノベーション創造プログラム（SIP）': 'This demonstration experiment is a Cross-ministerial Strategic Innovation Promotion Program (SIP) promoted by the Cabinet Office.',
  '「IoT 社会に対応したサイバー・フィジカル・セキュリティ」（管理法人：国立研究開発法人 新エネルギー・産業技術総合開発機構）によって実施されています。': 'It is implemented by "Cyber Physical Security for IoT Society" (Management corporation:National Research and Development Corporation New Energy and Industrial Technology Development Organization).',
  '関連サービス': 'Related Services',
  'T*Platsとは': 'What\'s T*Plats?',
  '(株)日立製作所': 'Hitachi, Ltd.',
  '国立研究開発法人 新エネルギー・産業技術総合開発機構': 'National Research and Development Corporation New Energy and Industrial Technology Development Organization',
  '管理サイト ログイン': 'Log in to the management site',
  // UI
  'ページトップへ': 'Home',
  'パンくずリスト': 'Breadcrumbs',
  'モーダルを閉じる': 'Close',
  '{prefix}ページへ': 'to {prefix} page',
  '最初の': 'First',
  '前の': 'Former',
  '次の': 'Latter',
  '最後の': 'Last',
  '« 前へ': '« Previous',
  '次へ »': 'Next »',
  'ログアウトしました': 'Logged out',
  // ページ名
  'トップページ': 'Home',
  'お知らせ': 'Notice',
  '利用規約': 'Terms of Service',
  'ユーザー登録': 'User Registration',
  '検証コード入力': 'Verification Code Input',
  'セキュリティコード登録': 'Security Code Registration',
  'パスワードリセット': 'Password Reset',
  'パスワード変更': 'Change Password',
  // 認証: 共通
  'メールアドレス': 'Email',
  'パスワード': 'Password',
  '検証コード': 'Verification code',
  'メールが受信できない場合は「@trust-shop.hitachi.co.jp」のドメインを受信する設定にご変更ください。': 'If you cannot receive an email from T*Plats, please change settings to receive the domain of 「@tplats.hitachi.co.jp」.',
  '登録する': 'To register',
  // ログイン
  'ユーザーアカウントをお持ちの方': 'Those who have a user account',
  'ログイン状態を保持': 'Keeping logged in',
  'パスワードをお忘れの方はこちら': 'Click here if you have forgotten your password',
  '初めて利用する方': 'Those who use for the first time',
  '{siteName}を初めて利用する方はユーザー登録が必要です。\n以下のユーザー登録ボタンを押して、メールアドレスとパスワードを設定してください。': 'If you are new to {siteName}, you need to register as a user.\n Please press the user registration button below and set your e-mail address and password.',
  '新規登録': 'Sign up',
  // ユーザー登録
  '新規ユーザーアカウントを作成します。\nパスワードは、半角英数字（※英字は大文字・小文字）を含む8桁以上で設定してください。\nボタン押下後、入力したメールアドレスに「検証コード」を送信します。': 'Create a new user account.\n Set the password with at least 8 digits including letters and numbers. \n After pressing the button, a "verification code" will be sent to the email address you entered.',
  'パスワード（確認）': 'Password (confirmation)',
  'ログイン画面に戻る': 'Return to login screen',
  // 検証コード入力
  '{username}に検証コードを送信しました。\n送信された検証コードを入力してください。\nこの後、セキュリティコードを入力していただく必要があります。ご案内されているセキュリティコードを準備してください。': 'You have submitted the verification code.\n Please enter the verification code sent.\n After this, if you have not registered your security code yet, you will need to enter it. Please have your security code ready.',
  'ユーザー登録はまだ完了していません。': 'User registration has not been completed yet.',
  '次へ進む': 'Proceed to the next',
  '検証コードを再送する': 'Resend the verification code.',
  // セキュリティコード登録
  'アカウントとの紐付けのため、管理者よりご案内されているセキュリティコードを登録してください。': 'Enter the security code provided by your administrator to complete user registration.',
  'セキュリティコード': 'Security code',
  // パスワードリセット
  'パスワードをリセットし、検証コードを送信します。\n{siteName}に登録しているメールアドレスを入力してください。': 'Reset your password and send a verification code. \nPlease enter the email address registered in {siteName}.',
  '検証コードを送信': 'Send verification code',
  // パスワード変更
  'パスワード変更します。\n新しいパスワードは、半角英数字（※英字は大文字・小文字）を含む8桁以上で設定してください。': 'Change your password.\n Set the password with at least 8 digits including letters and numbers.',
  '新しいパスワード': 'New password',
  '新しいパスワード（確認）': 'New password (confirmation)',
  // エラー: 共通
  '検証コードを確認してください。': 'Please confirm the verification code.',
  'パスワードは、大文字、小文字、数字を含む、8桁以上の値を設定してください。': 'Please Set the password to a value of 8 digits or more, including uppercase letters, lowercase letters, and numbers.',
  '試行制限に達しました。時間をあけて再度お試しください。': 'The trial limit has been reached. Please wait a while and try again.',
  // エラー: ログイン
  'メールアドレスもしくはパスワードが間違っています。再度入力してください。': 'The email address or password is incorrect. Please try again.',
  // エラー: ユーザー登録
  'すでにユーザーが登録されています。検証コードの入力が完了していない場合は、ログイン画面からログインし、ユーザー登録を完了してください。パスワードを忘れた場合は、ログイン画面からパスワード変更を行ってください。': 'The user is already registered. If you have not completed entering the verification code, log in from the login screen and complete user registration. If you forget your password, please change it from the login screen.',
  // エラー: セキュリティコード登録
  'ユーザ登録できませんでした。QRコード（URL)、セキュリティコードを再度確認してください。': 'Could not register as a user. Please reconfirm the QR code (URL) or security code.',
  '該当するデータが存在しません。管理者に確認してください。': 'The corresponding data does not exist. Check with your administrator.',
  'すでにユーザ登録は完了しています。ログイン画面からログインしてください。': 'User registration has already been completed. Please log in from the login screen.',
  'セキュリティコードが一致しません。': 'Security code does not match.',
  'ユーザー登録時にエラーが発生しました。({statusCode})': 'An error occurred during user registration.({statusCode})',
  // エラー: パスワード変更
  '新しいパスワードと、新しいパスワード（確認）が一致しません。': 'The new password and the new password (confirmation) do not match.',
  // トップページ
  '場所で探す': 'Search Location',
  '検索結果{count}{range}': 'Total search results: {count} Displayed results: {range}',
  '登録センサー': 'Registered sensors:',
  '{sensorCount}台': '{sensorCount}',
  '{count}件': '{count}',
  '{start} - {end}件を表示': '{start} - {end}',
  '検索結果のページネーション': 'Search results of pagination',
  '検索': 'Search',
  '{trustIndex}とは？': 'What is the {trustIndex}?',
  // 詳細ページ
  'IoTセンサーによるリアルタイム状況を表示しています。': 'Displaying the real-time status by the IoT sensor.',
  '住所': 'Address',
  'GoogleMapで場所を確認': 'Find in Google Maps',
  'くわしい情報はこちら': 'Please Click here for more information',
  '信頼度をシェアする': 'Share the trust index',
  'Twitter': 'Twitter',
  'Facebook': 'Facebook',
  'コピー': 'Copy',
  'リンクをコピーしました': 'Link is copied',
  '…もっと読む': '… Read more',
  '現在の状況': 'Current status',
  '対策状況': 'Status of countermeasures',
  'もっと見る': 'More',
  '閉じる': 'Close',
  '感染症.感染症対策状況タブ': 'Infection control ',
  '対策状況.感染症対策状況タブ': 'status',
  'エリア別.エリア別センサー情報タブ': 'Sensor information',
  'センサー情報.エリア別センサー情報タブ': 'by area',
  'お知らせ.お知らせタブ': 'notice',
  '地図.地図タブ': 'Map',
  'アンケート.アンケートタブ': 'Survey',
  '情報更新日時：{text}': 'Store information updated：<br class="h-spBreak">{text}',
  '第三者チェック': 'Verified by third party',
  '対応なし': 'N/A',
  '第三者チェック履歴': 'History of third party check',
  '第三者によるチェックを行っています。': 'Is checked by a third party.',
  'センサー情報一覧': 'Sensor information list',
  '情報を取得できませんでした。時間をおいて再度お試しください。': 'Failed to get the display data. <br class="h-spBreak">Please try again later.',
  '{text} センサー情報': '{text} Sensor information',
  '{year}年': '{year}',
  'YYYY年M月D日.お知らせpublishedAt': 'MMMM D, YYYY',
  '登録情報がありません。': 'No registration information',
  'お客様満足度アンケート': 'Customer Survey',
  'いつもご利用いただき誠にありがとうございます。よりお客様にご満足いただけるサービスが提供できるよう、アンケートを行っております。ぜひご意見をお聞かせください。': 'Thank you for using our services. We are conducting this survey so that we can provide you with more satisfactory services. Please let us know your opinions!',
  'はい.アンケート回答:2択': 'YES',
  'いいえ.アンケート回答:2択': 'NO',
  'その他.アンケート回答:2択': 'Other',
  '満足.アンケート回答:満足度': 'Satisfied',
  'やや満足.アンケート回答:満足度': 'Slightly satisfied',
  'どちらともいえない.アンケート回答:満足度': 'Not sure',
  'やや不満.アンケート回答:満足度': 'Slightly dissatisfied',
  '不満.アンケート回答:満足度': 'Dissatisfied',
  'ここに記入してください。（任意）.アンケート回答:テキストエリア': 'lease fill in this form(optional)',
  '本アンケートは、各店舗の顧客満足度把握が目的です。個人情報の取得は行いませんので、ご入力はお控え下さい。各店舗への誹謗中傷はお控え下さい。': 'The purpose of this survey is to understand customer satisfaction at each store.Please do not enter your personal information as we will not obtain it.Please refrain from slandering or slandering specific organizations or individuals. ',
  'アンケートを送信する': 'Submit',
  'アンケート回答結果の登録が完了しました。': 'Survey data was registered.',
  'アンケート回答登録時にエラーが発生しました。': 'An unexpected error has occurred. Issues with survey data registration.',
  // 対策観点
  '換気': 'Ventilation',
  '建築基準法の必要換気量を確保 （一人当たり30㎥/時もしくは、床と窓の面積比が５:1程度）': 'Maintain the ventilation volume required under the Building Standards Act <br>(30 ㎥/hour per person, or a floor-area-to-window-area ratio of about 5:1).',
  '換気設備の運転状況を確認、または定期的な窓の開放を実施': 'Regularly check the operating status of ventilation equipment and open windows.',
  'トイレ消毒': 'Disinfection of restrooms',
  'トイレ定期清掃・消毒、消毒液設置を実施': 'Regularly clean and disinfect restrooms, and provide hand sanitizers.',
  '認証の取得': 'Obtain a certificate',
  '感染防止対策に対する自治体等の第三者が発行する認証を取得': 'Obtaining certification issued by third parties such as a local government as evidence that the facility executes infection prevention/control measures.',
  // グラフ
  'グラフの見方について': 'About the graphs',
  'CO2濃度': 'CO2 Concentration',
  '屋内における二酸化炭素濃度は、400～1500ppm程度の間を上下します。屋内でも窓/換気扇/入口等の開閉により、濃度は低くなります。': 'The concentration of carbon dioxide indoors fluctuates between 400 and 1500 ppm. Even indoors, the concentration will be low near entrances and will be lowered by opening windows or using ventilation fans.',
  '濃度が低ければ、屋外の二酸化炭素濃度と同等であり、センサー周辺には、二酸化炭素を発生させる原因が少ないものと判断します。逆に濃度が高ければ、密閉空間の換気が不十分であるかもしくは二酸化炭素を発生させる原因が多く存在します。つまり、密集している状態の可能性が高いと考えます。換気状況を見える化できていることを、グラフからご理解頂けます。': 'A low concentration is equivalent to the carbon dioxide concentration outdoors, and it is judged that there are few sources that generate carbon dioxide around the sensor. A high concentration, however, indicates that multiple sources that generate carbon dioxide are nearby and ventilation of an enclosed space is insufficient. In other words, it is highly possible that the carbon-dioxide density is high. The graph allows the ventilation status to become visible.',
  '換気が必要': 'Ventilation Required',
  'やや換気が必要': 'Slight Ventilation Required',
  'よい.CO2濃度': 'Good',
  '室温': 'Room Temperature',
  '屋内における温度は、季節/冷暖房/換気等の状況変化により、0～35℃程度の間を上下します。': 'The indoor temperature fluctuates between 0 and 35° C depending on the conditions such as the season, the outside temperature, and ventilation.',
  '「機械換気未設置の場合は、常時、窓を少し開け、室温は18 ℃を目安にすること」との内容が政府より公表されています。': 'The government has announced that, as a guide, the room temperature should be kept to 18° C, and if mechanical ventilation is not installed, the windows should be opened a little.',
  '高い.室温': 'High',
  'よい.室温': 'Good',
  '低い.室温': 'Low',
  '湿度': 'Humidity',
  '屋内における湿度は、季節/冷暖房/換気等の状況変化により、0～100%の間を上下します。': 'Humidity indoors fluctuates between 0 and 100% depending on the conditions such as the season, heating, and ventilation.',
  '飛沫拡散防止の観点から「湿度は40%以上を目安にすること」との内容が政府より公表されています。': 'To prevent the spread of droplets, the government has announced that the humidity should be 40% or higher.',
  '高い.湿度': 'High',
  'よい.湿度': 'Good',
  '乾燥している': 'Low',
  '騒音': 'Noise',
  '店内の人の密集/密接状況を騒音の大小で表示します。': 'The amount of noise is related to the level of congestion and crowding in a store.',
  '騒がしい': 'Very Noisy',
  'にぎやか': 'Noisy',
  'ふつう': 'Normal',
  '静か': 'Quiet',
  '混雑指数': 'Congestion Index',
  '店内の人の密集/密接状況を周辺のBluetooth機器数から求めた指数で表示します。': 'The level of congestion and crowding in a store is displayed as an index calculated from the number of Bluetooth devices in the vicinity.',
  '混雑': 'Congested',
  'やや混雑': 'Slightly Crowded',
  'よい.混雑指数': 'Good',
  '暑さ指数': 'WBGT',
  '暑さ指数（WBGT（湿球黒球温度）：Wet Bulb Globe Temperature）': 'WBGT：Wet Bulb Globe Temperature',
  '室内を前提に、簡易的にWBGTを求めたものであり、熱中症になりやすさを示しています。': 'A simple WBGT method which calculates the risk of heat stroke indoors.',
  '危険.暑さ指数': 'Extreme Threat',
  '厳重警戒.暑さ指数': 'High Threat',
  '警戒.暑さ指数': 'Moderate Threat',
  '注意.暑さ指数': 'Elevated Threat',
  'ほぼ安全.暑さ指数': 'Low Threat',
  '3hours': '3hours',
  '1day': '1day',
  '1week': '1week',
  '表示がうまくいかない場合は、リロードするか時間をおいて再度アクセスしてください。': 'Note: If the graphs do not load correctly, please refresh the page. Alternatively, wait a while, and then try accessing this page again.',
  // 判定基準について
  '判定基準値について': 'About the judgment criteria',
  'センサーで取得した各チェック項目の判定基準値は下記となります。': 'The standard values for judgements of the check-item values acquired by sensors are as follows.',
  // トラスト指数の内訳
  'トラスト指数の内訳': 'Items of T*Plats Index',
  '換気/トイレ消毒の2カテゴリのチェック項目': 'There are two check categories: ventilation and toilet disinfection.',
  'IoTセンサーでの情報開示有無': 'With or without information from IoT sensors and measured values.',
  '第三者チェックの実施有無': 'With or without checks by a third party and certification.',
  '第三者チェックを行っているか': 'Is this store checked by a third party and certification?',
  // よくあるご質問
  'よくあるご質問': 'FAQ',
  'よくあるご質問一覧のページネーション': 'Pagination of FAQ',
  // エリア別センサー詳細
  'エリア別センサー': 'Sensor',
  '店舗詳細にもどる': 'Backward',
  // サイネージ表示
  'データ取得日時：{text}': 'Information updated：{text}',
  // 404
  '404 Not Found': '404 Not Found',
  '指定されたページが、見つかりませんでした。': 'The designated page was not found.',
  'URLが間違っているかページの移動または削除等の理由により閲覧することができません。': 'It cannot be viewed because the URL is incorrect or the page is moved or deleted.',
  'トップページより再度ご確認ください。': 'Please check again from home page.',
  'トップページへ': 'Home',
  // 規約
  'SLA': 'SLA',
  'サービス仕様書': 'Service Specifications',
}
