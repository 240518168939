import { cognitoSignOut } from '@/assets/modules/cognito'
import { Auth } from 'aws-amplify'

const state = () => ({
  isLoggedIn: false,
  idToken: '',
  username: '',
})

const getters = {
  isLoggedIn(state) {
    return state.isLoggedIn
  },
  idToken(state) {
    return state.idToken
  },
  username(state) {
    return state.username
  },
}

const mutations = {
  setIsLoggedIn(store, isLoggedIn) {
    store.isLoggedIn = isLoggedIn
  },
  setIdToken(store, idToken) {
    store.idToken = idToken
  },
  setUsername(store, username) {
    store.username = username
  },
  removeState(store) {
    store.isLoggedIn = false
    store.idToken = ''
    state.email = null
  },
}

const actions = {
  async restoreCognitoUserData({ commit }) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user.storage.keepLogin === 'true') {
        commit('setIdToken', user.signInUserSession.idToken.jwtToken)
        commit('setUsername', user.attributes.email)
        commit('setIsLoggedIn', true)
      } else {
        await cognitoSignOut()
      }
    } catch {
      commit('removeState')
    }
  },
  async updateCognitoUserData({ commit }) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      commit('setIdToken', user.signInUserSession.idToken.jwtToken)
      commit('setUsername', user.attributes.email)
      commit('setIsLoggedIn', true)
    } catch {
      commit('removeState')
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
