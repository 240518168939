<template>
  <component :is="layout" />
</template>

<script>
import { layouts } from '@/router'

export default {
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: (chank) => {
        return chank ? `${chank} | ${this.siteTitle}` : this.siteTitle
      },
      htmlAttrs: {
        lang: this.$i18n.locale,
        'data-portal': this.portalId,
      },
      link: [
        {
          vmid: 'icon:ico',
          rel: 'icon',
          href: `/img/favicon_${this.portalId}.ico`,
        },
        {
          vmid: 'icon:svg',
          rel: 'icon',
          href: `/img/favicon_${this.portalId}.svg`,
          type: 'image/svg+xml',
        },
        {
          vmid: 'icon:apple',
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `/img/apple-touch-icon_${this.portalId}.png`,
        },
        {
          vmid: 'icon:android',
          rel: 'icon',
          type: 'image/png',
          sizes: '256x256',
          href: `/img/android-chrome-icon_${this.portalId}.png`,
        },
      ],
      meta: [
        {
          name: 'viewport',
          vmid: 'viewport',
          content: 'width=device-width,initial-scale=1.0,shrink-to-fit=no',
        },
      ],
    }
  },
  provide() {
    return {
      setModal: this.setModal,
    }
  },
  data() {
    return {
      isModal: false,
      scrollY: null,
      defaultPortalId: 'shops',
    }
  },
  computed: {
    siteTitle() {
      return this.$t(`${this.portalId}.おみせのトラスト`)
    },
    layoutName() {
      return this.$route.meta.layout || 'default'
    },
    layout() {
      return layouts[this.layoutName]
    },
  },
  watch: {
    isModal(value) {
      if (value) this.fixedScroll()
      else this.releaseScroll()
    },
  },
  async beforeMount() {
    await this.$store.dispatch('auth/restoreCognitoUserData')
  },
  methods: {
    setModal(value, nextScrollTop) {
      this.isModal = value

      if (typeof nextScrollTop === 'number') this.scrollY = nextScrollTop
    },
    enableModal() {
      this.setModal(true)
    },
    disableModal() {
      this.setModal(false)
    },
    fixedScroll() {
      this.scrollY = document.documentElement.scrollTop || window.scrollY
      document.documentElement.classList.add('-modal')
      document.documentElement.style.top = `${this.scrollY * -1}px`
    },
    releaseScroll() {
      document.documentElement.classList.remove('-modal')
      document.documentElement.style.top = ''
      window.scrollTo(0, this.scrollY || 0)
    },
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
