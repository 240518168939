import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'
import { PortalIdList } from '@/libs/define'

Vue.use(Router)
Vue.use(VueMeta)

export const layouts = {
  default: () => import('@/layouts/default.vue'),
  auth: () => import('@/layouts/auth.vue'),
  sensor: () => import('@/layouts/sensor.vue'),
  signage: () => import('@/layouts/signage.vue'),
}

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior(to, from, save) {
    if (to.params?.portalId === from.params?.portalId) {
      if (save) return save
      if (to.name === from.name) return
    }
    return { x: 0, y: 0 }
  },
  routes: [
    // 古いパスからのリダイレクト処理
    {
      path: '/',
      redirect: {
        name: 'Index',
        params: {
          portalId: 'shops',
        },
      },
    },
    {
      path: '/shops/shop-info',
      redirect(to) {
        return {
          name: 'ShopDetail',
          params: {
            id: to.query.key,
            portalId: 'shops',
          },
          query: {},
        }
      },
    },
    // パス定義
    {
      path: '/sensorinfo',
      name: 'SensorDetail',
      meta: { layout: 'sensor' },
      component: () => import('@/views/sensorinfo.vue'),
    },
    {
      path: '/:portalId',
      name: 'Index',
      component: () => import('@/views/_portalId/index.vue'),
    },
    {
      path: '/:portalId/login',
      name: 'Login',
      meta: { layout: 'auth' },
      component: () => import('@/views/_portalId/login.vue'),
    },
    {
      path: '/:portalId/signup',
      name: 'Signup',
      meta: { layout: 'auth' },
      component: () => import('@/views/_portalId/signup/index.vue'),
    },
    {
      path: '/:portalId/signup/account_verifications',
      name: 'AccountVerifications',
      meta: { layout: 'auth' },
      component: () => import('@/views/_portalId/signup/account_verifications.vue'),
    },
    {
      path: '/:portalId/auth',
      name: 'Auth',
      meta: { layout: 'auth' },
      component: () => import('@/views/_portalId/auth.vue'),
    },
    {
      path: '/:portalId/password_reset',
      name: 'PasswordReset',
      meta: { layout: 'auth' },
      component: () => import('@/views/_portalId/password_reset.vue'),
    },
    {
      path: '/:portalId/password_change',
      name: 'PasswordChange',
      meta: { layout: 'auth' },
      component: () => import('@/views/_portalId/password_change.vue'),
    },
    {
      path: '/:portalId/terms',
      name: 'Terms',
      component: () => import('@/views/_portalId/terms.vue'),
    },
    {
      path: '/:portalId/faq',
      name: 'Faq',
      component: () => import('@/views/_portalId/faq/index.vue'),
    },
    {
      path: '/:portalId/faq/:subCategory',
      name: 'FaqDetail',
      component: () => import('@/views/_portalId/faq/_subCategory.vue'),
    },
    {
      path: '/:portalId/404',
      name: 'Error',
      component: () => import('@/views/_portalId/error.vue'),
    },
    {
      path: '/:portalId/:id',
      name: 'ShopDetail',
      component: () => import('@/views/_portalId/_id/index.vue'),
    },
    {
      path: '/:portalId/:id/signage',
      name: 'ShopSignage',
      meta: { layout: 'signage' },
      component: () => import('@/views/_portalId/_id/signage.vue'),
    },
    // 404リダイレクトする
    {
      path: '*',
      redirect: {
        name: 'Error',
        params: {
          portalId: 'shops',
        },
      },
    },
  ],
})

router.afterEach((to, from) => {
  // ページ遷移が発生した場合に共通レイアウトにフォーカスが残らないようにする
  if (to.path !== from.path) {
    document.body.focus()
  }
})

router.beforeEach((to, from, next) => {
  if (
    to.params.portalId &&
    PortalIdList.every((portalId) => portalId !== to.params.portalId)
  ) {
    next({
      name: 'Error',
      params: {
        portalId: 'shops',
      },
    })
  } else {
    next()
  }
})

export default router
