import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import { i18n } from '@/plugins/vue-i18n'
import '@/plugins/filters'
import '@/plugins/vuelidate'
import '@/plugins/vue-toasted'
import '@/plugins/dayjs'
import '@/plugins/gsap'
import '@/plugins/aws-amplify'
import { globalMixin } from '@/mixins'

Vue.config.performance = true
Vue.prototype.$log = console.log.bind(console)

if (process.env.NODE_ENV === 'local') {
  const { worker } = require('./mocks/browser')
  worker.start({
    onUnhandledRequest: 'bypass',
  })
}

Vue.component('AppLink', () => import('@/components/AppLink'))

Vue.mixin(globalMixin)

new Vue({
  el: '#app',
  store,
  router,
  i18n,
  template: '<App/>',
  components: {
    App,
  },
})
