import common from './common'
import shops from './shops'
import schools from './schools'
import hospitals from './hospitals'

export default {
  ...common,
  shops,
  schools,
  hospitals,
}
