import { Auth } from 'aws-amplify'

export const cognitoForgotPassword = async (username) => {
  let result = {
    isError: false,
  }

  try {
    result.data = await Auth.forgotPassword(username)
  } catch (error) {
    result.isError = true
    result.data = error
  }

  return result
}
