const state = () => ({
  overridePortalId: 'shops',
})

const getters = {
  portalId(state) {
    return state.overridePortalId
  },
}

const mutations = {
  setOverridePortalId(store, overridePortalId) {
    store.overridePortalId = overridePortalId
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
