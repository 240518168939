const state = () => ({
  hasEnglishPage: true,
})

const getters = {
  hasEnglishPage(state) {
    return state.hasEnglishPage
  },
}

const mutations = {
  setHasEnglishPage(store, hasEnglishPage) {
    store.hasEnglishPage = hasEnglishPage
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
