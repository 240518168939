import store from '@/store'
import { Auth } from 'aws-amplify'

export const cognitoSignIn = async (username, password, keepLogin) => {
  let result = {
    isError: false,
  }

  try {
    result.data = await Auth.signIn(username, password)
    localStorage.setItem('keepLogin', `${keepLogin}`)
    await store.dispatch('auth/updateCognitoUserData')
  } catch (error) {
    result.isError = true
    result.data = error
  }

  return result
}
