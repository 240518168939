import Amplify from 'aws-amplify'

export default Amplify.configure({
  Auth: {
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTIFY_POOL_ID,
    region: 'ap-northeast-1',
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
  Analytics: {
    disabled: true,
  },
})
