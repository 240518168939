import store from '@/store'
import { Auth } from 'aws-amplify'

export const cognitoSignOut = async () => {
  let result = {
    isError: false,
  }

  try {
    result.data = await Auth.signOut()
    localStorage.removeItem('keepLogin')
    await store.dispatch('auth/updateCognitoUserData')
  } catch (error) {
    result.isError = true
    result.data = error
  }

  return result
}
