/* eslint-disable prettier/prettier */
export default {
  // 病室のトラスト
  // サイト名
  'おみせのトラスト': 'T*Plats',
  // ヘッダー
  'おみせのトラストは、お店のトラスト情報（衛生対策）を見える化する実証実験です。': 'T*Plats visualizes data related to trustworthiness of hygiene measures implemented by hospitals.',
  // フッター
  '感染症対策を実施してる店舗を掲載しています。対策状況を確認することで、利用者が店舗を安心して利用できることを実証します。': 'T*Plats provides the status of infection control measures of hospitals so that patients can visit them with peace of mind.',
  // トップ
  '施設の環境をみつめよう。': 'Keep your eyes on the <br class="h-spBreak">facility\'s environment.',
  '現代のニューノーマルに対応した衛生的で安心な施設をご利用いただくため施設の信頼性（トラスト）情報をリアルタイムに見える化します。': 'Based on information about reliability (trustworthiness) stored and visualized in real time, <br>find Hospitals that are safe and hygienic according to the standards of the new normal.',
  '店舗名 / 施設名で探す': 'Search by hospital name',
  'おみせのトラスト指数': 'T*Plats Index',
  'お店と施設それぞれの衛生対策状況をパーセンテージで示す、おみせのトラスト独自の信頼性指数です。トラスト指数が高いほど、衛生対策の信頼性が高いお店となります。': 'T*Plats Index is an unique trust index of hygiene measures implemented by hospitals. A room with a higher \nT*Plats index indicates the room is highly reliable in terms of hygiene measures.  ',
  '{badge}は、IoTセンサーによるリアルタイム状況を表示している店舗です。': '{badge} : Real-time status checked by IoT sensors',
  '{badge}は、第三者によるチェックを行っている店舗です。': '{badge} : Verified by a third party',
  'IoTセンサー導入店舗': 'IoT sensor introduction hospital',
  '第三者によるチェックを行っている店舗': 'Hospitals that are checked by a third party',
  '該当するお店が見つかりませんでした。検索条件を変更して再度お試しください。': 'No applicable hospital was found. Please change the search conditions and try again.',
  // 詳細画面
  'おみせのサイトへ': 'Website',
  '対策状況のうち、店舗の対策情報は{shopName}、施設の対策情報は{facilityName}が登録しています。': 'Of the countermeasure status, {shopName} is registered for hospital countermeasure information, and {facilityName} is registered for equipment countermeasure information.',
  '店舗の対策': 'Hospital measures',
  '施設の対策': 'Measures taken by the facility',
  'おみせの取り組み': 'Hospital efforts',
  // 対策観点
  '店舗消毒': 'Regular Disinfection',
  'ドアノブ、券売機、セルフドリンクコーナーなどのお客様フロアにある設備の消毒': 'Disinfect equipment that patients use such as doorknobs, payment machines, waiting rooms, etc.',
  'お客様の退席後、テーブル上の消毒もしくはテーブルクロスの交換を実施': 'Disinfect equipment after patients leave.',
  'お客様の安全': 'Patient safety',
  '入口における消毒液を設置、入店時お客様にて消毒実施': 'Provide hand sanitizing stations around entrances and ask people to disinfect their hands upon entering.',
  '入店時お客様に検温を実施': 'Measure the temperature of people upon entering.',
  'テーブル間距離': 'Distance between tables',
  'グループ同士で１ｍの間隔が空けられているもしくはパーティションで区切られている': 'Groups are separated by the distance of 1 meter or by partitions.',
  '従業員の安全衛生管理': 'Employee safety and health management',
  '従業員の検温、体調管理を実施': 'Conduct temperature and physical condition checks on employees.',
  '従業員のマスクまたはフェイスガードの着用': 'Staff wear masks or face guards.',
  // トラスト指数の内訳
  'お店の衛生対策状況': 'Status of hygiene measures in hospitals',
  '換気/店舗消毒/お客様の安全/テーブル間距離/従業員の安全衛生管理/トイレ消毒の6カテゴリのチェック項目': 'Check items in 6 categories: ventilation / indoor disinfection / patient safety / person-to-person distance / medical personnel safety and hygiene management / toilet disinfection. and presence or absence of photo publication.',
  '施設の衛生対策状況': 'Sanitation in the facility',
  'お店にセンサーを設置し、数値を開示しているか': 'Does this hospital room disclose information through sensors and measured values?',
}
