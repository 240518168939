/* eslint-disable prettier/prettier */
export default {
  // 共通
  // ヘッダー
  'ログイン': 'ログイン',
  'ログアウト': 'ログアウト',
  '表示言語': '表示言語',
  '表示モード': '表示モード',
  '標準': '標準',
  'サイネージモード.サイネージモード(タブレット)': 'サイネージモード',
  '(タブレット).サイネージモード(タブレット)': '(タブレット)',
  'サイネージモード.サイネージモード(スマートフォン)': 'サイネージモード',
  '(スマートフォン).サイネージモード(スマートフォン)': '(スマートフォン)',
  // フッター
  '{siteName}について': '{siteName}について',
  '本実証実験は、内閣府が進める戦略的イノベーション創造プログラム（SIP）': '本実証実験は、内閣府が進める戦略的イノベーション創造プログラム（SIP）',
  '「IoT 社会に対応したサイバー・フィジカル・セキュリティ」（管理法人：国立研究開発法人 新エネルギー・産業技術総合開発機構）によって実施されています。': '「IoT 社会に対応したサイバー・フィジカル・セキュリティ」（管理法人：国立研究開発法人 新エネルギー・産業技術総合開発機構）によって実施されています。',
  '関連サービス': '関連サービス',
  'T*Platsとは': 'T*Platsとは',
  '(株)日立製作所': '(株)日立製作所',
  '国立研究開発法人 新エネルギー・産業技術総合開発機構': '国立研究開発法人 新エネルギー・産業技術総合開発機構',
  '管理サイト ログイン': '管理サイト ログイン',
  // UI
  'ページトップへ': 'ページトップへ',
  'パンくずリスト': 'パンくずリスト',
  'モーダルを閉じる': 'モーダルを閉じる',
  '{prefix}ページへ': '{prefix}ページへ',
  '最初の': '最初の',
  '前の': '前の',
  '次の': '次の',
  '最後の': '最後の',
  '« 前へ': '« 前へ',
  '次へ »': '次へ »',
  'ログアウトしました': 'ログアウトしました',
  // ページ名
  'トップページ': 'トップページ',
  'お知らせ': 'お知らせ',
  '利用規約': '利用規約',
  'ユーザー登録': 'ユーザー登録',
  '検証コード入力': '検証コード入力',
  'セキュリティコード登録': 'セキュリティコード登録',
  'パスワードリセット': 'パスワードリセット',
  'パスワード変更': 'パスワード変更',
  // 認証: 共通
  'メールアドレス': 'メールアドレス',
  'パスワード': 'パスワード',
  '検証コード': '検証コード',
  'メールが受信できない場合は「@trust-shop.hitachi.co.jp」のドメインを受信する設定にご変更ください。': 'メールが受信できない場合は「@tplats.hitachi.co.jp」のドメインを受信する設定にご変更ください。',
  '登録する': '登録する',
  // ログイン
  'ユーザーアカウントをお持ちの方': 'ユーザーアカウントをお持ちの方',
  'ログイン状態を保持': 'ログイン状態を保持',
  'パスワードをお忘れの方はこちら': 'パスワードをお忘れの方はこちら',
  '初めて利用する方': '初めて利用する方',
  '{siteName}を初めて利用する方はユーザー登録が必要です。\n以下のユーザー登録ボタンを押して、メールアドレスとパスワードを設定してください。': '{siteName}を初めて利用する方はユーザー登録が必要です。\n以下のユーザー登録ボタンを押して、メールアドレスとパスワードを設定してください。',
  '新規登録': '新規登録',
  // ユーザー登録
  '新規ユーザーアカウントを作成します。\nパスワードは、半角英数字（※英字は大文字・小文字）を含む8桁以上で設定してください。\nボタン押下後、入力したメールアドレスに「検証コード」を送信します。': '新規ユーザーアカウントを作成します。\nパスワードは、半角英数字（※英字は大文字・小文字）を含む8桁以上で設定してください。\nボタン押下後、入力したメールアドレスに「検証コード」を送信します。',
  'パスワード（確認）': 'パスワード（確認）',
  'ログイン画面に戻る': 'ログイン画面に戻る',
  // 検証コード入力
  '{username}に検証コードを送信しました。\n送信された検証コードを入力してください。\nこの後、セキュリティコードを入力していただく必要があります。ご案内されているセキュリティコードを準備してください。': '{username}に検証コードを送信しました。\n送信された検証コードを入力してください。\nこの後、セキュリティコードを入力していただく必要があります。ご案内されているセキュリティコードを準備してください。',
  'ユーザー登録はまだ完了していません。': 'ユーザー登録はまだ完了していません。',
  '次へ進む': '次へ進む',
  '検証コードを再送する': '検証コードを再送する',
  // セキュリティコード登録
  'アカウントとの紐付けのため、管理者よりご案内されているセキュリティコードを登録してください。': 'アカウントとの紐付けのため、管理者よりご案内されているセキュリティコードを登録してください。',
  'セキュリティコード': 'セキュリティコード',
  // パスワードリセット
  'パスワードをリセットし、検証コードを送信します。\n{siteName}に登録しているメールアドレスを入力してください。': 'パスワードをリセットし、検証コードを送信します。\n{siteName}に登録しているメールアドレスを入力してください。',
  '検証コードを送信': '検証コードを送信',
  // パスワード変更
  'パスワード変更します。\n新しいパスワードは、半角英数字（※英字は大文字・小文字）を含む8桁以上で設定してください。': 'パスワード変更します。\n新しいパスワードは、半角英数字（※英字は大文字・小文字）を含む8桁以上で設定してください。',
  '新しいパスワード': '新しいパスワード',
  '新しいパスワード（確認）': '新しいパスワード（確認）',
  // エラー: 共通
  '検証コードを確認してください。': '検証コードを確認してください。',
  'パスワードは、大文字、小文字、数字を含む、8桁以上の値を設定してください。': 'パスワードは、大文字、小文字、数字を含む、8桁以上の値を設定してください。',
  '試行制限に達しました。時間をあけて再度お試しください。': '試行制限に達しました。時間をあけて再度お試しください。',
  // エラー: ログイン
  'メールアドレスもしくはパスワードが間違っています。再度入力してください。': 'メールアドレスもしくはパスワードが間違っています。再度入力してください。',
  // エラー: ユーザー登録
  'すでにユーザーが登録されています。検証コードの入力が完了していない場合は、ログイン画面からログインし、ユーザー登録を完了してください。パスワードを忘れた場合は、ログイン画面からパスワード変更を行ってください。': 'すでにユーザーが登録されています。検証コードの入力が完了していない場合は、ログイン画面からログインし、ユーザー登録を完了してください。パスワードを忘れた場合は、ログイン画面からパスワード変更を行ってください。',
  // エラー: セキュリティコード登録
  'ユーザ登録できませんでした。QRコード（URL)、セキュリティコードを再度確認してください。': 'ユーザ登録できませんでした。QRコード（URL)、セキュリティコードを再度確認してください。',
  '該当するデータが存在しません。管理者に確認してください。': '該当するデータが存在しません。管理者に確認してください。',
  'すでにユーザ登録は完了しています。ログイン画面からログインしてください。': 'すでにユーザ登録は完了しています。ログイン画面からログインしてください。',
  'セキュリティコードが一致しません。': 'セキュリティコードが一致しません。',
  'ユーザー登録時にエラーが発生しました。({statusCode})': 'ユーザー登録時にエラーが発生しました。({statusCode})',
  // エラー: パスワード変更
  '新しいパスワードと、新しいパスワード（確認）が一致しません。': '新しいパスワードと、新しいパスワード（確認）が一致しません。',
  // トップページ
  '場所で探す': '場所で探す',
  '{trustIndex}とは？': '{trustIndex}とは？',
  '検索結果{count}{range}': '検索結果{count}{range}',
  '登録センサー': '登録センサー',
  '{sensorCount}台': '{sensorCount}台',
  '{count}件': '{count}件',
  '{start} - {end}件を表示': '{start} - {end}件を表示',
  '検索結果のページネーション': '検索結果のページネーション',
  '検索': '検索',
  // 詳細ページ
  'IoTセンサーによるリアルタイム状況を表示しています。': 'IoTセンサーによるリアルタイム状況を表示しています。',
  '住所': '住所',
  'GoogleMapで場所を確認': 'GoogleMapで場所を確認',
  'くわしい情報はこちら': 'くわしい情報はこちら',
  '信頼度をシェアする': '信頼度をシェアする',
  'Twitter': 'Twitter',
  'Facebook': 'Facebook',
  'コピー': 'コピー',
  'リンクをコピーしました': 'リンクをコピーしました',
  '…もっと読む': '…もっと読む',
  '現在の状況': '現在の状況',
  '対策状況': '対策状況',
  'もっと見る': 'もっと見る',
  '閉じる': '閉じる',
  '感染症.感染症対策状況タブ': '感染症',
  '対策状況.感染症対策状況タブ': '対策状況',
  'エリア別.エリア別センサー情報タブ': 'エリア別',
  'センサー情報.エリア別センサー情報タブ': 'センサー情報',
  'お知らせ.お知らせタブ': 'お知らせ',
  '地図.地図タブ': '地図',
  'アンケート.アンケートタブ': 'アンケート',
  '情報更新日時：{text}': '情報更新日時：<br class="h-spBreak">{text}',
  '第三者チェック': '第三者チェック',
  '対応なし': '対応なし',
  '第三者チェック履歴': '第三者チェック履歴',
  '第三者によるチェックを行っています。': '第三者によるチェックを行っています。',
  'センサー情報一覧': 'センサー情報一覧',
  '情報を取得できませんでした。時間をおいて再度お試しください。': '情報を取得できませんでした。<br class="h-spBreak">時間をおいて再度お試しください。',
  '{text} センサー情報': '{text} センサー情報',
  '{year}年': '{year}年',
  'YYYY年M月D日.お知らせpublishedAt': 'YYYY年M月D日',
  '登録情報がありません。': '登録情報がありません。',
  'お客様満足度アンケート': 'お客様満足度アンケート',
  'いつもご利用いただき誠にありがとうございます。よりお客様にご満足いただけるサービスが提供できるよう、アンケートを行っております。ぜひご意見をお聞かせください。': 'いつもご利用いただき誠にありがとうございます。よりお客様にご満足いただけるサービスが提供できるよう、アンケートを行っております。ぜひご意見をお聞かせください。',
  'はい.アンケート回答:2択': 'はい',
  'いいえ.アンケート回答:2択': 'いいえ',
  'その他.アンケート回答:2択': 'その他',
  '満足.アンケート回答:満足度': '満足',
  'やや満足.アンケート回答:満足度': 'やや満足',
  'どちらともいえない.アンケート回答:満足度': 'どちらともいえない',
  'やや不満.アンケート回答:満足度': 'やや不満',
  '不満.アンケート回答:満足度': '不満',
  'ここに記入してください。（任意）.アンケート回答:テキストエリア': 'ここに記入してください。（任意）',
  '本アンケートは、各店舗の顧客満足度把握が目的です。個人情報の取得は行いませんので、ご入力はお控え下さい。各店舗への誹謗中傷はお控え下さい。': '本アンケートは、各店舗の顧客満足度把握が目的です。個人情報の取得は行いませんので、ご入力はお控え下さい。各店舗への誹謗中傷はお控え下さい。',
  'アンケートを送信する': 'アンケートを送信する',
  'アンケート回答結果の登録が完了しました。': 'アンケート回答結果の登録が完了しました。',
  'アンケート回答登録時にエラーが発生しました。': 'アンケート回答登録時にエラーが発生しました。',
  // 対策観点
  '換気': '換気',
  '建築基準法の必要換気量を確保 （一人当たり30㎥/時もしくは、床と窓の面積比が５:1程度）': '建築基準法の必要換気量を確保 （一人当たり30㎥/時もしくは、床と窓の面積比が５:1程度）',
  '換気設備の運転状況を確認、または定期的な窓の開放を実施': '換気設備の運転状況を確認、または定期的な窓の開放を実施',
  'トイレ消毒': 'トイレ消毒',
  'トイレ定期清掃・消毒、消毒液設置を実施': 'トイレ定期清掃・消毒、消毒液設置を実施',
  '認証の取得': '認証の取得',
  '感染防止対策に対する自治体等の第三者が発行する認証を取得': '感染防止対策に対する自治体等の第三者が発行する認証を取得',
  // グラフ
  'グラフの見方について': 'グラフの見方について',
  'CO2濃度': 'CO2濃度',
  '屋内における二酸化炭素濃度は、400～1500ppm程度の間を上下します。屋内でも窓/換気扇/入口等の開閉により、濃度は低くなります。': '屋内における二酸化炭素濃度は、400～1500ppm程度の間を上下します。屋内でも窓/換気扇/入口等の開閉により、濃度は低くなります。',
  '濃度が低ければ、屋外の二酸化炭素濃度と同等であり、センサー周辺には、二酸化炭素を発生させる原因が少ないものと判断します。逆に濃度が高ければ、密閉空間の換気が不十分であるかもしくは二酸化炭素を発生させる原因が多く存在します。つまり、密集している状態の可能性が高いと考えます。換気状況を見える化できていることを、グラフからご理解頂けます。': '濃度が低ければ、屋外の二酸化炭素濃度と同等であり、センサー周辺には、二酸化炭素を発生させる原因が少ないものと判断します。逆に濃度が高ければ、密閉空間の換気が不十分であるかもしくは二酸化炭素を発生させる原因が多く存在します。つまり、密集している状態の可能性が高いと考えます。換気状況を見える化できていることを、グラフからご理解頂けます。',
  '換気が必要': '換気が必要',
  'やや換気が必要': 'やや換気が必要',
  'よい.CO2濃度': 'よい',
  '室温': '室温',
  '屋内における温度は、季節/冷暖房/換気等の状況変化により、0～35℃程度の間を上下します。': '屋内における温度は、季節/冷暖房/換気等の状況変化により、0～35℃程度の間を上下します。',
  '「機械換気未設置の場合は、常時、窓を少し開け、室温は18 ℃を目安にすること」との内容が政府より公表されています。': '「機械換気未設置の場合は、常時、窓を少し開け、室温は18 ℃を目安にすること」との内容が政府より公表されています。',
  '高い.室温': '高い',
  'よい.室温': 'よい',
  '低い.室温': '低い',
  '湿度': '湿度',
  '屋内における湿度は、季節/冷暖房/換気等の状況変化により、0～100%の間を上下します。': '屋内における湿度は、季節/冷暖房/換気等の状況変化により、0～100%の間を上下します。',
  '飛沫拡散防止の観点から「湿度は40%以上を目安にすること」との内容が政府より公表されています。': '飛沫拡散防止の観点から「湿度は40%以上を目安にすること」との内容が政府より公表されています。',
  '高い.湿度': '高い',
  'よい.湿度': 'よい',
  '乾燥している': '乾燥している',
  '騒音': '騒音',
  '店内の人の密集/密接状況を騒音の大小で表示します。': '店内の人の密集/密接状況を騒音の大小で表示します。',
  '騒がしい': '騒がしい',
  'にぎやか': 'にぎやか',
  'ふつう': 'ふつう',
  '静か': '静か',
  '混雑指数': '混雑指数',
  '店内の人の密集/密接状況を周辺のBluetooth機器数から求めた指数で表示します。': '店内の人の密集/密接状況を周辺のBluetooth機器数から求めた指数で表示します。',
  '混雑': '混雑',
  'やや混雑': 'やや混雑',
  'よい.混雑指数': 'よい',
  '暑さ指数': '暑さ指数',
  '暑さ指数（WBGT（湿球黒球温度）：Wet Bulb Globe Temperature）': '暑さ指数（WBGT（湿球黒球温度）：Wet Bulb Globe Temperature）',
  '室内を前提に、簡易的にWBGTを求めたものであり、熱中症になりやすさを示しています。': '室内を前提に、簡易的にWBGTを求めたものであり、熱中症になりやすさを示しています。',
  '危険.暑さ指数': '危険',
  '厳重警戒.暑さ指数': '厳重警戒',
  '警戒.暑さ指数': '警戒',
  '注意.暑さ指数': '注意',
  'ほぼ安全.暑さ指数': 'ほぼ安全',
  '3hours': '3hours',
  '1day': '1day',
  '1week': '1week',
  '表示がうまくいかない場合は、リロードするか時間をおいて再度アクセスしてください。':'表示がうまくいかない場合は、リロードするか時間をおいて再度アクセスしてください。',
  // 判定基準について
  '判定基準値について': '判定基準値について',
  'センサーで取得した各チェック項目の判定基準値は下記となります。': 'センサーで取得した各チェック項目の判定基準値は下記となります。',
  // トラスト指数の内訳
  'トラスト指数の内訳': 'T*Plats指数の内訳',
  '換気/トイレ消毒の2カテゴリのチェック項目': '換気/トイレ消毒の2カテゴリのチェック項目',
  'IoTセンサーでの情報開示有無': 'IoTセンサーでの情報開示有無及び測定数値',
  '第三者チェックの実施有無': '第三者チェックの実施有無及び認証有無',
  '第三者チェックを行っているか': '第三者チェックを行っているか、及び、認証有無',
  // よくあるご質問
  'よくあるご質問': 'よくあるご質問',
  'よくあるご質問一覧のページネーション': 'よくあるご質問一覧のページネーション',
  // エリア別センサー詳細
  'エリア別センサー': 'エリア別センサー',
  '店舗詳細にもどる': '店舗詳細にもどる',
  // サイネージ表示
  'データ取得日時：{text}': 'データ取得日時：{text}',
  // 404
  '404 Not Found': '404 Not Found',
  '指定されたページが、見つかりませんでした。': '指定されたページが、見つかりませんでした。',
  'URLが間違っているかページの移動または削除等の理由により閲覧することができません。': 'URLが間違っているかページの移動または削除等の理由により閲覧することができません。',
  'トップページより再度ご確認ください。': 'トップページより再度ご確認ください。',
  'トップページへ': 'トップページへ',
  // 規約
  'SLA': 'SLA',
  'サービス仕様書': 'サービス仕様書',
}
