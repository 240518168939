export default {
  watch: {
    '$metaInfo.title': {
      handler(title) {
        this.$emit('setPageTitle', title)
      },
      immediate: true,
    },
    '$data.pageParents': {
      handler(pageParents) {
        this.$emit('setPageParents', pageParents)
      },
      immediate: true,
    },
  },
}
